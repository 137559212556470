<template>
  <div v-if="shouldShowAISettings && fieldData.ai_settings" class="ai-settings">
    <el-row class="mb-1">
      <el-checkbox v-model="fieldData.ai_settings.isAIEnabled"
        >Use AI to create and manage your own data</el-checkbox
      >
    </el-row>
    <div v-if="fieldData.ai_settings.isAIEnabled">
      <el-row class="mb-1">
        Utilize AI to create content, identify keywords, assess sentiment and
        summarize information using your own data.
      </el-row>
      <el-row>
        <el-col :span="24">
          <p class="sel-content">Which action would you like to perform?</p>
          <el-select
            class="sel-width"
            size="medium"
            v-model="fieldData.ai_settings.selectedOperation"
            clearable
            placeholder="Select An Operation"
          >
            <el-option
              v-for="(option, index) in filteredOperationOptions"
              :key="index"
              :label="option.label"
              :value="option.value"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <br />
      <el-row>
        <el-col :span="24">
          <p class="sel-content">Select the source field</p>
          <el-select
            class="sel-width"
            size="medium"
            v-model="fieldData.ai_settings.selectedSourceField"
            clearable
            placeholder="Select An Option"
          >
            <el-option
              v-for="(option, index) in filteredFields"
              :key="index"
              :label="option.label"
              :value="option.key"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <br />
      <div
        v-if="
          fieldData.ai_settings.selectedOperation &&
          fieldData.ai_settings.selectedSourceField
        "
        class="note"
      >
        {{ noteMessage }}
      </div>
      <br />
      <el-row v-show="!disableAdditionalFields">
        <el-col :span="24">
          <p class="sel-content">Include Additional fields</p>
          <el-select
            class="sel-width"
            size="medium"
            v-model="fieldData.ai_settings.selectedAdditionalFields"
            multiple
            clearable
            placeholder="Select Options"
            collapse-tags=""
          >
            <el-option
              v-for="(option, index) in filteredAdditionalFields"
              :key="index"
              :label="option.label"
              :value="option.key"
            ></el-option>
          </el-select>
        </el-col>
      </el-row>
      <br />
      <el-row>
        <el-col :span="24">
          <span
            >AI can quickly generate personalized content based on your
            attributes.</span
          ><br />
        </el-col>
      </el-row>
      <br />
      <el-row>
        <el-col :span="24">
          <el-checkbox v-model="fieldData.ai_settings.showPromptMessage"
            >Customize your prompt as you needed</el-checkbox
          >
          <textarea
            v-if="fieldData.ai_settings.showPromptMessage"
            v-model="fieldData.ai_settings.userPrompt"
            class="prompt-textarea mt-1"
            placeholder="Should you require customization or prefer to provide your own input you can give here... "
          ></textarea>
        </el-col>
      </el-row>
      <!-- <el-row v-if="fieldData.ai_settings.selectedOperation === 'summary'">
        <el-col :span="24">
          <p class="sel-content">Select an Optional Style</p>
          <el-select class="sel-width" size="medium" v-model="fieldData.ai_settings.selectedStyle" clearable
            placeholder="Select Style">
            <el-option label="Formal" value="formal"></el-option>
            <el-option label="Informal" value="informal"></el-option>
            <el-option label="Friendly" value="friendly"></el-option>
            <el-option label="Persuasive" value="persuasive"></el-option>
          </el-select>
        </el-col>
      </el-row> -->
      <br />
      <el-row v-if="shouldShowRunOnAction">
        <el-col :span="24">
          <p class="sel-content">Run on When</p>
          <p v-if="!isRunOnActionSelected" class="info-message">
            Please select any one of the following run on actions.
          </p>
          <el-checkbox-group v-model="fieldData.ai_settings.runOnAction">
            <el-checkbox label="Added">Add New Data</el-checkbox>
            <el-checkbox label="Updated">on Save/Update</el-checkbox>
            <el-checkbox label="OnClick">Realtime button</el-checkbox>
          </el-checkbox-group>
        </el-col> </el-row
      ><br />
      <el-row>
        <el-input
          v-if="fieldData.ai_settings.runOnAction.includes('OnClick')"
          placeholder="Enter content for realtime button"
          v-model="fieldData.ai_settings.realtimeButtonContent"
        ></el-input>
      </el-row>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import TemplateBuilderHelper from "@/mixins/templateBuilderHelper";

export default {
  name: "AISettings",
  mixins: [TemplateBuilderHelper],

  data() {
    return {
      // fieldData: {},
      operationOptions: [
        { label: "Content Creation", value: "content_generation" },
        { label: "Keyword Extraction", value: "keyword_extraction" },
        { label: "Sentiment Analysis", value: "sentiment_analysis" },
        { label: "Summarization", value: "summary" },
        { label: "Image Generation", value: "image_generation" },
      ],
    };
  },
  props: ["fieldData", "fieldsData"],
  mounted() {},
  computed: {
    shouldShowAISettings() {
      const validInputTypes = [
        "SINGLE_LINE_TEXT",
        "MULTI_LINE_TEXT",
        "SELECT",
        "MULTI_SELECT",
        "RICH_TEXT",
        "IMAGE",
      ];
      return (
        validInputTypes.includes(this.fieldData.inputType) ||
        validInputTypes.includes(this.fieldData.input_type)
      );
    },
    generatePromptText() {
      if (this.fieldData.ai_settings)
        return this.generatePrompt(this.fieldData, {}, this.fieldsData, true);
      return "";
    },
    filteredOperationOptions() {
      switch (this.fieldData.inputType) {
        case "MULTI_LINE_TEXT":
          return this.operationOptions;
        case "SELECT":
          return this.operationOptions.filter(
            (option) =>
              option.value === "summary" ||
              option.value === "content_generation"
          );
        case "SINGLE_LINE_TEXT":
          return this.operationOptions.filter(
            (option) =>
              option.value === "sentiment_analysis" ||
              option.value === "keyword_extraction"
          );
        case "MULTI_SELECT":
          return this.operationOptions.filter(
            (option) => option.value === "keyword_extraction"
          );
        case "IMAGE":
          return this.operationOptions.filter(
            (option) => option.value === "image_generation"
          );
        default:
          return [];
      }
    },

    filteredFields() {
      if (!this.fieldData.ai_settings.selectedOperation) return [];
      const excludedInputType = this.fieldData.inputType;

      switch (this.fieldData.ai_settings.selectedOperation) {
        case "content_generation":
          return this.fieldsData.filter(
            (field) =>
              [
                "SINGLE_LINE_TEXT",
                "MULTI_LINE_TEXT",
                "SELECT",
                "MULTI_SELECT",
                "IMAGE",
              ].includes(field.inputType) &&
              !(field === this.field && field.inputType === excludedInputType)
          );
        case "sentiment_analysis":
          return this.fieldsData.filter(
            (field) =>
              [
                "SINGLE_LINE_TEXT",
                "MULTI_LINE_TEXT",
                "MULTI_SELECT",
                "IMAGE",
              ].includes(field.inputType) &&
              !(field === this.field && field.inputType === excludedInputType)
          );
        case "keyword_extraction":
          return this.fieldsData.filter(
            (field) =>
              (field.inputType === "SINGLE_LINE_TEXT" ||
                field.inputType === "MULTI_LINE_TEXT" ||
                field.inputType === "MULTI_SELECT" ||
                field.inputType === "IMAGE") &&
              !(field === this.field && field.inputType === excludedInputType)
          );
        case "summary":
          return this.fieldsData.filter(
            (field) =>
              ["MULTI_LINE_TEXT"].includes(field.inputType) &&
              !(field === this.field && field.inputType === excludedInputType)
          );
        case "image_generation":
          return this.fieldsData.filter(
            (field) =>
              ["MULTI_LINE_TEXT", "RICH_TEXT", "SINGLE_LINE_TEXT"].includes(
                field.inputType
              ) &&
              !(field === this.field && field.inputType === excludedInputType)
          );
        default:
          return [];
      }
    },
    disableAdditionalFields() {
      return (
        this.fieldData.ai_settings.selectedOperation === "sentiment_analysis" ||
        this.fieldData.ai_settings.selectedOperation === "keyword_extraction"
      );
    },
    filteredAdditionalFields() {
      return this.fieldsData.filter(
        (field) =>
          field.key !== this.fieldData.key &&
          field.key !== this.fieldData.ai_settings.selectedSourceField
      );
    },
    noteMessage() {
      if (!this.fieldData.ai_settings.selectedOperation) {
        return "Please select an operation.";
      }
      switch (this.fieldData.ai_settings.selectedOperation) {
        case "content_generation":
          return "For example, you can create content for job titles such as Fullstack Developer.";
        case "keyword_extraction":
          return "For example, you can extract keywords from descriptions of products.";
        case "sentiment_analysis":
          return "For example, you can analyze the sentiment of customer reviews.";
        case "summary":
          return "For example, you can summarize lengthy articles or documents.";
        case "image_generation":
          return "For example, you can generate images based on descriptive text or prompts.";
        default:
          return "Please select an operation.";
      }
    },
    shouldShowRunOnAction() {
      return (
        this.shouldShowAISettings && this.fieldData.ai_settings.isAIEnabled
      );
    },
    isRunOnActionSelected() {
      return this.fieldData.ai_settings.runOnAction.length > 0;
    },
  },
  methods: {
    initializeFieldData(field) {
      this.fieldData = field || {};
      this.fieldData.ai_settings = this.fieldData.ai_settings || {};
      this.fieldData.ai_settings.isAIEnabled =
        this.fieldData.ai_settings.isAIEnabled ?? false;
      this.fieldData.ai_settings.selectedOperation =
        this.fieldData.ai_settings.selectedOperation ?? null;
      this.fieldData.ai_settings.selectedSourceField =
        this.fieldData.ai_settings.selectedSourceField ?? null;
      this.fieldData.ai_settings.selectedAdditionalFields =
        this.fieldData.ai_settings.selectedAdditionalFields ?? [];
      this.fieldData.ai_settings.selectedStyle =
        this.fieldData.ai_settings.selectedStyle ?? null;
      this.fieldData.ai_settings.runOnAction =
        this.fieldData.ai_settings.runOnAction ?? [];
      this.fieldData.ai_settings.userPrompt =
        this.fieldData.ai_settings.userPrompt ?? "";
      this.fieldData.ai_settings.realtimeButtonContent =
        this.fieldData.ai_settings.realtimeButtonContent ?? "";
    },
    async sendFormDataToBackend() {
      try {
        const session_id = `${this.getActiveWorkspace.company_id}#${this.getAuthenticatedUser._id}`;
        const response = await axios.post("https://ai.esigns.io/ai_features/", {
          Enter_your_prompt: this.fieldData.ai_settings.userPrompt,
          session_id: session_id,
          prompt_type: this.fieldData.ai_settings.selectedOperation,
          flag: true,
        });
        console.log("Backend response:", response.data);
      } catch (error) {
        console.error("Error sending data to backend:", error);
      }
    },
  },
};
</script>

<style scoped>
.ai-settings {
  padding: 20px;
  max-height: 635px; /* Adjust this value based on your needs */
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: thin; /* Firefox */
}

.sel-content {
  font-size: 1rem;
  font-weight: 500;
}

.sel-width {
  width: 100%;
}

.prompt-textarea {
  width: 100%;
  height: 150px;
  padding: 10px;
  font-size: 0.8rem;
  border-radius: 4px;
  scrollbar-width: thin;
  overflow-y: auto;
}

.apply-btn {
  float: right;
}

.note {
  width: 100%;
  height: 50px;
  border-left: 5px solid #409eff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: #ecf8ff;
}
</style>
